import React from 'react'

import { Translate, withLocalize } from 'react-localize-redux'
import ReactModal from 'react-modal'

import SurveyModal from '../Modals/SurveyModal'
import { Status } from './Status'
import { StatusIcon } from './StatusIcon'
import { store } from '../../helpers/index'
import { mobileSurveys } from '../../constants'
import { recordsActions, alertActions } from '../../actions'
import { surveyServices } from '../../services/survey.services'
import CBSModal from '../Modals/CBSModal'
import EQModal from '../Modals/EQ/EQModal'

class SurveyItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSurveyModal: false,
      showCBSModal: false,
      showEQModal: false,
      showIncompleteModal: false,
    }

    this.handleAction = this.handleAction.bind(this)
  }

  closeModal = () => {
    this.setState({
      showSurveyModal: false,
      showCBSModal: false,
      showEQModal: false,
      showIncompleteModal: false,
    })
  }

  handleAction() {
    const { record, survey, surveyType, surveyPath } = this.props
    const { dispatch } = store
    const baseline_portal_id = record.baseline_portal_id

    let records = JSON.parse(sessionStorage.getItem('records'))
    const index = records.findIndex(
      i => i.baseline_portal_id === baseline_portal_id
    )

    if (index >= 0) {
      const j = records[index].surveys[surveyPath].findIndex(
        i => i.type_name === surveyType
      )

      if (j >= 0) {
        const m = records[index].surveys[surveyPath][j].surveys.findIndex(
          i => i.name === survey.name
        )

        if (m >= 0) {
          const portal_id = baseline_portal_id
          const params = { survey: survey, portal_id: portal_id }
          surveyServices.update(params).then(
            response => {
              if (response.data.survey && !response.data.survey.completed) {
                this.setState({ showIncompleteModal: true })
              }
              records[index].surveys[surveyPath][j].surveys[m] =
                response.data.survey
              records[index].sports = response.data.sports

              dispatch(recordsActions.refresh(records))
            },
            error => {
              if (error.response) {
                const errors = error.response.data.errors
                if (errors) {
                  errors.forEach(e => {
                    dispatch(alertActions.error(e))
                  })
                }
              }
            }
          )
        }
      }
    }
    this.closeModal()
  }

  handleSurveyClick = () => {
    const { survey } = this.props

    if (survey.availability && !survey.completed) {
      if (
        survey.name ===
        this.props.translate('surveyItems.cambridgeBrainSciences')
      ) {
        this.setState({
          showCBSModal: true,
        })
      } else if (survey.name === 'EuroQol 5 Dimension Questionnaire') {
        this.setState({
          showEQModal: true,
        })
      } else {
        this.setState({
          showSurveyModal: true,
        })
      }
    }
  }

  render() {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
    const {
      showSurveyModal,
      showCBSModal,
      showEQModal,
      showIncompleteModal,
    } = this.state
    const { survey, id, record, surveyPath, surveyType } = this.props

    return (
      <div className="col-lg-10">
        <ReactModal
          style={{
            overlay: { backgroundColor: 'rgba(33, 33, 33, 0.85)' },
            content: {
              maxWidth: 500,
              maxHeight: 210,
              width: '80%',
              margin: 'auto',
            },
          }}
          isOpen={showIncompleteModal}
          onRequestClose={this.closeModal}
        >
          <div style={{ textAlign: 'center', paddingTop: '0.5rem' }}>
            <b>
              You have just closed a survey that is not complete.
              <br />
              Please go back and complete the survey
            </b>
          </div>
          <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.setState({ showIncompleteModal: false })
              }}
              style={{ width: '75px' }}
            >
              Close
            </button>
          </div>
        </ReactModal>
        <SurveyModal
          showModal={showSurveyModal}
          baseline_portal_id={record.baseline_portal_id}
          survey={survey}
          surveyType={surveyType}
          surveyPath={surveyPath}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        <CBSModal
          showModal={showCBSModal}
          baseline_portal_id={record.baseline_portal_id}
          survey={survey}
          surveyType={surveyType}
          surveyPath={surveyPath}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        {showEQModal ? (
          <EQModal
            showModal={showEQModal}
            baseline_portal_id={record.baseline_portal_id}
            handleAction={this.handleAction}
            onClose={this.closeModal}
          />
        ) : null}
        <div
          className="card"
          style={{ width: '100%', borderRadius: 0, cursor: 'pointer' }}
          id={id}
          onClick={this.handleSurveyClick}
        >
          <div className="card-body" style={{ padding: '0.6rem' }} id={id}>
            <div className="row">
              <div
                style={{
                  marginLeft: '1.3rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <StatusIcon survey={survey} />
                <div style={{ marginLeft: '1.3rem' }}>
                  <h5 style={{ margin: 0 }} id={id}>
                    {isMobile && mobileSurveys[survey.name]
                      ? mobileSurveys[survey.name]
                      : survey.name}
                  </h5>
                  <Status survey={survey} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withLocalize(SurveyItem)
