import 'rc-tooltip/assets/bootstrap.less'
import 'rc-slider/assets/index.css'
import React from 'react'
import ReactModal from 'react-modal'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { XCircle } from 'react-feather'
import { Translate, withLocalize } from 'react-localize-redux'
import Slider from 'rc-slider'

import logo from '../../../images/Affiliates/qol.jpeg'
import { store } from '../../../helpers/index'
import '../Modal.css'
import { euroQolServices } from '../../../services'
import { alertActions } from '../../../actions'

const { createSliderWithTooltip } = Slider

const NewSlider = createSliderWithTooltip(Slider)

let cancel = { exec: null }

const marks = {
  0: '0 - The worst health you can imagine',
  50: '50',
  100: '100 - The best health you can imagine',
}

class EQModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      eq5d_mb_5l_can_eng: null,
      eq5d_sc_5l_can_eng: null,
      eq5d_ua_5l_can_eng: null,
      eq5d_pd_5l_can_eng: null,
      eq5d_ad_5l_can_eng: null,
      eq5d5l_vas2_can_eng: 50,
      loading: true,
      step: 1,
    }
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidMount() {
    const { dispatch } = store
    Modal.setAppElement('body')

    euroQolServices.get(cancel).then(
      response => {
        const data = response.data.data

        this.setState({
          loading: false,
          eq5d_mb_5l_can_eng: data.eq5d_mb_5l_can_eng
            ? data.eq5d_mb_5l_can_eng
            : null,
          eq5d_sc_5l_can_eng: data.eq5d_sc_5l_can_eng
            ? data.eq5d_sc_5l_can_eng
            : null,
          eq5d_ua_5l_can_eng: data.eq5d_ua_5l_can_eng
            ? data.eq5d_ua_5l_can_eng
            : null,
          eq5d_pd_5l_can_eng: data.eq5d_pd_5l_can_eng
            ? data.eq5d_pd_5l_can_eng
            : null,
          eq5d_ad_5l_can_eng: data.eq5d_ad_5l_can_eng
            ? data.eq5d_ad_5l_can_eng
            : null,
          eq5d5l_vas2_can_eng: data.eq5d5l_vas2_can_eng
            ? data.eq5d5l_vas2_can_eng
            : null,
        })
      },
      error => {
        this.setState({ loading: false })
        dispatch(alertActions.error('Error fetching euroqol information'))
      }
    )
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleSliderChange = value => {
    this.setState({ eq5d5l_vas2_can_eng: value })
  }

  nextStep = () => {
    const { step } = this.state
    this.setState({ step: step + 1 })
  }

  prevStep = () => {
    const { step } = this.state
    this.setState({ step: step - 1 })
  }

  hasPrevStep = () => {
    const { step } = this.state
    return step > 1 ? true : false
  }

  hasNextStep = () => {
    const { step } = this.state
    return step < 6 ? true : false
  }

  handleSave() {
    const { dispatch } = store
    const { baseline_portal_id } = this.props
    const {
      eq5d_mb_5l_can_eng,
      eq5d_sc_5l_can_eng,
      eq5d_ua_5l_can_eng,
      eq5d_pd_5l_can_eng,
      eq5d_ad_5l_can_eng,
      eq5d5l_vas2_can_eng,
    } = this.state

    const params = {
      portal_id: baseline_portal_id,
      eq5d_mb_5l_can_eng: eq5d_mb_5l_can_eng,
      eq5d_sc_5l_can_eng: eq5d_sc_5l_can_eng,
      eq5d_ua_5l_can_eng: eq5d_ua_5l_can_eng,
      eq5d_pd_5l_can_eng: eq5d_pd_5l_can_eng,
      eq5d_ad_5l_can_eng: eq5d_ad_5l_can_eng,
      eq5d5l_vas2_can_eng: eq5d5l_vas2_can_eng,
    }

    euroQolServices.post(params).then(
      response => {
        dispatch(
          alertActions.success('EuroQol 5 Dimension Questionnaire Submitted')
        )
        this.props.handleAction()
      },
      error => {
        dispatch(
          alertActions.error(
            'Something went wrong, could not post survey response.'
          )
        )
      }
    )
  }

  render() {
    const { showModal, onClose } = this.props
    const {
      step,
      eq5d_mb_5l_can_eng,
      eq5d_sc_5l_can_eng,
      eq5d_ua_5l_can_eng,
      eq5d_pd_5l_can_eng,
      eq5d_ad_5l_can_eng,
      eq5d5l_vas2_can_eng,
    } = this.state

    let renderedForm = null

    if (step === 1) {
      renderedForm = (
        <table>
          <td>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>
                  Please click the ONE box that best describes your health
                  TODAY.
                </b>
              </div>
            </td>
          </td>
          <tr>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>1) MOBILITY</b>
                <div style={{ marginLeft: '1rem' }}>
                  <div className="form-check" style={{ marginTop: '1rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_mb_5l_can_eng"
                        id="eq5d_mb_5l_can_eng"
                        value="1"
                        checked={eq5d_mb_5l_can_eng === '1'}
                        onClick={this.handleChange}
                      />
                      I have no problems in walking about
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_mb_5l_can_eng"
                        id="eq5d_mb_5l_can_eng"
                        value="2"
                        checked={eq5d_mb_5l_can_eng === '2'}
                        onClick={this.handleChange}
                      />
                      I have slight problems in walking about
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_mb_5l_can_eng"
                        id="eq5d_mb_5l_can_eng"
                        value="3"
                        checked={eq5d_mb_5l_can_eng === '3'}
                        onClick={this.handleChange}
                      />
                      I have moderate problems in walking about
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_mb_5l_can_eng"
                        id="eq5d_mb_5l_can_eng"
                        value="4"
                        checked={eq5d_mb_5l_can_eng === '4'}
                        onClick={this.handleChange}
                      />
                      I have severe problems in walking about
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_mb_5l_can_eng"
                        id="eq5d_mb_5l_can_eng"
                        value="5"
                        checked={eq5d_mb_5l_can_eng === '5'}
                        onClick={this.handleChange}
                      />
                      I am unable to walk about
                    </label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <div style={{ padding: '1rem' }}>
              <b>
                © EuroQol Research Foundation. EQ-5D™ is a trade mark of the
                EuroQol Research Foundation
              </b>
            </div>
          </tr>
        </table>
      )
    } else if (step === 2) {
      renderedForm = (
        <table>
          <td>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>
                  Please click the ONE box that best describes your health
                  TODAY.
                </b>
              </div>
            </td>
          </td>
          <tr>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>2) SELF-CARE</b>
                <div style={{ marginLeft: '1rem' }}>
                  <div className="form-check" style={{ marginTop: '1rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_sc_5l_can_eng"
                        id="eq5d_sc_5l_can_eng"
                        value="1"
                        checked={eq5d_sc_5l_can_eng === '1'}
                        onClick={this.handleChange}
                      />
                      I have no problems washing or dressing myself
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_sc_5l_can_eng"
                        id="eq5d_sc_5l_can_eng"
                        value="2"
                        checked={eq5d_sc_5l_can_eng === '2'}
                        onClick={this.handleChange}
                      />
                      I have slight problems washing or dressing myself
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_sc_5l_can_eng"
                        id="eq5d_sc_5l_can_eng"
                        value="3"
                        checked={eq5d_sc_5l_can_eng === '3'}
                        onClick={this.handleChange}
                      />
                      I have moderate problems washing or dressing myself
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_sc_5l_can_eng"
                        id="eq5d_sc_5l_can_eng"
                        value="4"
                        checked={eq5d_sc_5l_can_eng === '4'}
                        onClick={this.handleChange}
                      />
                      I have severe problems washing or dressing myself
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_sc_5l_can_eng"
                        id="eq5d_sc_5l_can_eng"
                        value="5"
                        checked={eq5d_sc_5l_can_eng === '5'}
                        onClick={this.handleChange}
                      />
                      I am unable to wash or dress myself
                    </label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <div style={{ padding: '1rem' }}>
              <b>
                © EuroQol Research Foundation. EQ-5D™ is a trade mark of the
                EuroQol Research Foundation
              </b>
            </div>
          </tr>
        </table>
      )
    } else if (step === 3) {
      renderedForm = (
        <table>
          <td>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>
                  Please click the ONE box that best describes your health
                  TODAY.
                </b>
              </div>
            </td>
          </td>
          <tr>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>
                  3) USUAL ACTIVITIES{' '}
                  <i>
                    (e.g. work, study, housework, family or leisure activities)
                  </i>
                </b>
                <div style={{ marginLeft: '1rem' }}>
                  <div className="form-check" style={{ marginTop: '1rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ua_5l_can_eng"
                        id="eq5d_ua_5l_can_eng"
                        value="1"
                        checked={eq5d_ua_5l_can_eng === '1'}
                        onClick={this.handleChange}
                      />
                      I have no problems doing my usual activities
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ua_5l_can_eng"
                        id="eq5d_ua_5l_can_eng"
                        value="2"
                        checked={eq5d_ua_5l_can_eng === '2'}
                        onClick={this.handleChange}
                      />
                      I have slight problems problems doing my usual activities
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ua_5l_can_eng"
                        id="eq5d_ua_5l_can_eng"
                        value="3"
                        checked={eq5d_ua_5l_can_eng === '3'}
                        onClick={this.handleChange}
                      />
                      I have moderate problems doing my usual activities
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ua_5l_can_eng"
                        id="eq5d_ua_5l_can_eng"
                        value="4"
                        checked={eq5d_ua_5l_can_eng === '4'}
                        onClick={this.handleChange}
                      />
                      I have severe problems doing my usual activities
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ua_5l_can_eng"
                        id="eq5d_ua_5l_can_eng"
                        value="5"
                        checked={eq5d_ua_5l_can_eng === '5'}
                        onClick={this.handleChange}
                      />
                      I am unable to do my usual activities
                    </label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr style={{ margin: '1rem 0' }}>
            <div style={{ padding: '1rem' }}>
              <b>
                © EuroQol Research Foundation. EQ-5D™ is a trade mark of the
                EuroQol Research Foundation
              </b>
            </div>
          </tr>
        </table>
      )
    } else if (step === 4) {
      renderedForm = (
        <table>
          <td>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>
                  Please click the ONE box that best describes your health
                  TODAY.
                </b>
              </div>
            </td>
          </td>
          <tr>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>4) PAIN / DISCOMFORT</b>
                <div style={{ marginLeft: '1rem' }}>
                  <div className="form-check" style={{ marginTop: '1rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_pd_5l_can_eng"
                        id="eq5d_pd_5l_can_eng"
                        value="1"
                        checked={eq5d_pd_5l_can_eng === '1'}
                        onClick={this.handleChange}
                      />
                      I have no pain or discomfort
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_pd_5l_can_eng"
                        id="eq5d_pd_5l_can_eng"
                        value="2"
                        checked={eq5d_pd_5l_can_eng === '2'}
                        onClick={this.handleChange}
                      />
                      I have slight pain or discomfort
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_pd_5l_can_eng"
                        id="eq5d_pd_5l_can_eng"
                        value="3"
                        checked={eq5d_pd_5l_can_eng === '3'}
                        onClick={this.handleChange}
                      />
                      I have moderate pain or discomfort
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_pd_5l_can_eng"
                        id="eq5d_pd_5l_can_eng"
                        value="4"
                        checked={eq5d_pd_5l_can_eng === '4'}
                        onClick={this.handleChange}
                      />
                      I have severe pain or discomfort
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_pd_5l_can_eng"
                        id="eq5d_pd_5l_can_eng"
                        value="5"
                        checked={eq5d_pd_5l_can_eng === '5'}
                        onClick={this.handleChange}
                      />
                      I have extreme pain or discomfort
                    </label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr style={{ margin: '1rem 0' }}>
            <div style={{ padding: '1rem' }}>
              <b>
                © EuroQol Research Foundation. EQ-5D™ is a trade mark of the
                EuroQol Research Foundation
              </b>
            </div>
          </tr>
        </table>
      )
    } else if (step === 5) {
      renderedForm = (
        <table>
          <td>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>
                  Please click the ONE box that best describes your health
                  TODAY.
                </b>
              </div>
            </td>
          </td>
          <tr>
            <td>
              <div style={{ padding: '1rem' }}>
                <b>5) ANXIETY / DEPRESSION</b>
                <div style={{ marginLeft: '1rem' }}>
                  <div className="form-check" style={{ marginTop: '1rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ad_5l_can_eng"
                        id="eq5d_ad_5l_can_eng"
                        value="1"
                        checked={eq5d_ad_5l_can_eng === '1'}
                        onClick={this.handleChange}
                      />
                      I am not anxious or depressed
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ad_5l_can_eng"
                        id="eq5d_ad_5l_can_eng"
                        value="2"
                        checked={eq5d_ad_5l_can_eng === '2'}
                        onClick={this.handleChange}
                      />
                      I am slightly anxious or depressed
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ad_5l_can_eng"
                        id="eq5d_ad_5l_can_eng"
                        value="3"
                        checked={eq5d_ad_5l_can_eng === '3'}
                        onClick={this.handleChange}
                      />
                      I am moderately anxious or depressed
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ad_5l_can_eng"
                        id="eq5d_ad_5l_can_eng"
                        value="4"
                        checked={eq5d_ad_5l_can_eng === '4'}
                        onClick={this.handleChange}
                      />
                      I am severely anxious or depressed
                    </label>
                  </div>
                  <div className="form-check" style={{ marginTop: '0.3rem' }}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="eq5d_ad_5l_can_eng"
                        id="eq5d_ad_5l_can_eng"
                        value="5"
                        checked={eq5d_ad_5l_can_eng === '5'}
                        onClick={this.handleChange}
                      />
                      I am extremely anxious or depressed
                    </label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr style={{ margin: '1rem 0' }}>
            <div style={{ padding: '1rem' }}>
              <b>
                © EuroQol Research Foundation. EQ-5D™ is a trade mark of the
                EuroQol Research Foundation
              </b>
            </div>
          </tr>
        </table>
      )
    } else if (step === 6) {
      renderedForm = (
        <table>
          <tr>
            <td style={{ width: '50%' }}>
              <div style={{ padding: '1rem' }}>
                <b>6)</b>
                <div style={{ marginLeft: '1rem' }}>
                  <ul>
                    <li>
                      We would like to know how good or bad your health is
                      TODAY.
                    </li>
                    <li>This scale is numbered from 0 to 100.</li>
                    <li>
                      100 means the best health you can imagine. 0 means the
                      worst health you can imagine.
                    </li>
                    <li>
                      Please click on the scale to indicate how your health is
                      TODAY.
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td style={{ width: '50%' }}>
              <div style={{ padding: '1rem', height: '220px' }}>
                <NewSlider
                  vertical
                  min={0}
                  max={100}
                  defaultValue={eq5d5l_vas2_can_eng}
                  marks={marks}
                  tipFormatter={value => value}
                  onAfterChange={this.handleSliderChange}
                />
              </div>
            </td>
          </tr>
          <tr style={{ margin: '1rem 0' }}>
            <td colSpan={2}>
              <div style={{ padding: '1rem' }}>
                <b>
                  © EuroQol Research Foundation. EQ-5D™ is a trade mark of the
                  EuroQol Research Foundation
                </b>
              </div>
            </td>
          </tr>
        </table>
      )
    }

    return (
      <div>
        <ReactModal
          style={{
            overlay: { backgroundColor: 'rgba(33, 33, 33, 0.85)' },
            content: {
              maxWidth: 800,
              width: '80%',
              margin: 'auto',
            },
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={onClose}
        >
          <XCircle
            size={32}
            onClick={onClose}
            style={{ cursor: 'pointer', float: 'right' }}
          />
          <div className="container mt-5">
            <img src={logo} />
            <br />
            <br />
            <h3>EuroQol 5 Dimension Questionnaire</h3>
            <br />
            <p>
              <b>Please Complete the survey below.</b>
            </p>
            <p>Thank you!</p>
            {renderedForm}
            <hr />
            <div>
              <div style={{ textAlign: 'right' }}>
                {this.hasPrevStep() ? (
                  <button
                    className="btn btn-secondary col-sm-5 col-lg-1 text-center"
                    style={{ margin: '1rem 0.25rem' }}
                    onClick={() => this.prevStep()}
                  >
                    Back
                  </button>
                ) : null}
                {this.hasNextStep() ? (
                  <button
                    onClick={() => this.nextStep()}
                    className="btn btn-secondary col-sm-5 col-lg-1 text-center"
                    style={{ margin: '1rem 0.25rem' }}
                  >
                    Next
                  </button>
                ) : null}
              </div>
              <div style={{ textAlign: 'right', marginTop: '1rem' }}>
                <button
                  className="btn btn-primary col-lg-3 text-center"
                  style={{ margin: '1rem 0.25rem' }}
                  onClick={this.handleSave}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const allSports = state.sport.sports

  return {
    allSports,
  }
}

export default withLocalize(connect(mapStateToProps)(EQModal))
