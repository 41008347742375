import React from 'react'
import { Link } from 'react-router-dom'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import siprc from '../../images/Affiliates/siprc.png'
import govCanada from '../../images/Affiliates/gov_canada.png'
import cihr from '../../images/Affiliates/cihr.jpg'
import shred from '../../images/ShredLogos/secondary_shred.png'
import './Footer.css'

export const Footer = () => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <footer className="footer">
      <div className="footer-secondary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12" style={{ cursor: 'grab' }}>
              <Slider {...settings}>
                <div>
                  <img
                    className="item affiliates"
                    alt="siprc logo"
                    src={siprc}
                    height={100}
                    width="auto"
                  />
                </div>
                <div>
                  <img
                    className="item affiliates"
                    alt="cihr logo"
                    src={cihr}
                    height={100}
                    width="auto"
                  />
                </div>
                <div>
                  <img
                    className="item affiliates"
                    alt="shred logo"
                    src={shred}
                    height={100}
                    width="auto"
                  />
                </div>
                <div>
                  <img
                    className="item affiliates"
                    alt="gov canada"
                    src={govCanada}
                    height={100}
                    width="auto"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-primary footer-background-color">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 footer-widget">
              <h5 className="heading text-white">
                QUICK{' '}
                <span className="text-primary">
                  LINKS
                </span>
              </h5>
              <ul className="quick-links">
                <li>
                  <Link to="/about" className="btn-custom-muted">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className="btn-custom-muted">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 footer-widget">
              <h5 className="heading text-white">
                CONTACT {' '}
                <span className="text-primary">
                  US
                </span>
              </h5>
              <address>
                <p className="text-primary">
                  SHRed Consequences of Concussion
                </p>
                <span>
                  KNB3300
                  <br />
                  Faculaty of Kinesiology,
                  <br />
                  Univ. of Calgary
                </span>
                <br />
                <br />
                <span>
                  2500 University Dr. NW
                  <br />
                  Calgary, AB
                  <br />
                  T2N 1N4
                </span>
              </address>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="text-primary"
              >
                  <a href="mailto:shredconsequences@ucalgary.ca">SHRedConsequences@ucalgary.ca</a>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="text-primary"
              >
                403-220-3394
              </div>
            </div>
            <span className="copyright">
              &copy; Copyright SHRed 2021
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
