import React from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import banner_1_jpg from '../../images/Banner/DSC_2148_1.jpg'
import banner_1_webp from '../../images/Banner/DSC_2148_1.webp'
import banner_2_jpg from '../../images/Banner/DSC_2256.jpg'
import banner_2_webp from '../../images/Banner/DSC_2256.webp'
import banner_3_jpg from '../../images/Banner/banner__3.jpeg'
import banner_3_webp from '../../images/Banner/banner__3.webp'
import banner_4_jpg from '../../images/Banner/Hockey_female1 2.jpg'
import banner_4_webp from '../../images/Banner/Hockey_female1-2.webp'
import './Banner.css'

export const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 10000,
    fade: true,
  }

  return (
    <div className="mb-page">
      <Slider {...settings}>
        <picture>
          <source srcSet={banner_1_webp} type="image/webp" />
          <source srcSet={banner_1_jpg} type="image/jpeg" />
          <img className="item banner" alt="banner" src={banner_1_jpg} />
        </picture>
        <picture>
          <source srcSet={banner_2_webp} type="image/webp" />
          <source srcSet={banner_2_jpg} type="image/jpeg" />
          <img className="item banner" alt="banner" src={banner_2_jpg} />
        </picture>
        <picture>
          <source srcSet={banner_3_webp} type="image/webp" />
          <source srcSet={banner_3_jpg} type="image/jpeg" />
          <img className="item banner" alt="banner" src={banner_3_jpg} />
        </picture>
        <picture>
          <source srcSet={banner_4_webp} type="image/webp" />
          <source srcSet={banner_4_jpg} type="image/jpeg" />
          <img className="item banner" alt="banner" src={banner_4_jpg} />
        </picture>
      </Slider>
    </div>
  )
}
