import {CancelToken} from "axios";

import {handleAxiosError} from '../helpers'
import {instance} from '../helpers'

export const signUpRequestServices = {
    create,
    get,
    update
}

function get(token, cancel) {
    return instance
        .get('/signup-request/' + token, {cancelToken: new CancelToken(c => (cancel.exec = c))})
        .then(response => {
            return response
        })
        .catch(error => {
            return handleAxiosError(error)
        })
}

function update(params, token) {
  return instance
    .put('/signup-request/' + token, params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function create(params) {
    return instance
        .post('/signup-request', params)
        .then(response => {
            return response
        })
        .catch(error => {
            return handleAxiosError(error)
        })
}
