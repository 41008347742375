import axios from 'axios'
import { push } from 'react-router-redux'

import { sessionActions } from '../actions'
import { store } from './store'

const { dispatch } = store

let api_url = 'http://localhost:5000'
let ui_url = 'http://localhost:3000'

const hostname = window && window.location && window.location.hostname

if (hostname === 'consequences.shredconcussions.ca') {
  api_url = 'https://consequences.shredconcussions.ca/api'
  ui_url = 'https://consequences.shredconcussions.ca'
} else if (hostname === 'test.consequences.shredconcussions.ca') {
  api_url = 'https://test.consequences.shredconcussions.ca'
  ui_url = 'https://test.consequences.shredconcussions.ca'
} else if (hostname === 'dev.consequences.shredconcussions.ca') {
  api_url = 'https://dev.consequences-api.shredconcussions.ca'
  ui_url = 'https://dev.consequences.shredconcussions.ca'
}

export function handleAxiosError(err) {
  if (err.response && err.response.data.msg === 'User is not authorized') {
    dispatch(sessionActions.destroy())
    dispatch(push('/'))
  }

  return Promise.reject(err)
}

export const instance = axios.create({
  baseURL: api_url,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': ui_url,
  },
})

export const staticFileInstance = axios.create({
  baseURL: api_url,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': ui_url,
  },
  responseType: 'blob',
})

staticFileInstance.interceptors.request.use(function(config) {
  config.headers['Accept-Language'] = sessionStorage.getItem('languageCode')
  return config
})

// Set the AUTH token for any request
instance.interceptors.request.use(function(config) {
  const token = sessionStorage.getItem('token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  config.headers['Accept-Language'] = sessionStorage.getItem('languageCode')
  return config
})
