import { MainConsent } from './MainConsent'


export const consentForms = {
  MainConsent: MainConsent,
}

export const consentContentRequired = {
  MainConsent: true,
}
