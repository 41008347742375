import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Translate, withLocalize } from 'react-localize-redux'

import { sessionActions, userActions } from '../../actions'
import { LoginModal } from '../Modals'
import globalTranslations from '../../localize/global.json'
import { store } from '../../helpers/index'

import dots from '../../images/Misc/siprc_dots.png'

class Topbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showLogin: false,
    }

    this.props.addTranslation(globalTranslations)

    this.handleLogout = this.handleLogout.bind(this)
    this.toggleLogin = this.toggleLogin.bind(this)
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
  }

  handleLogout(event) {
    event.preventDefault()
    const { dispatch } = this.props
    dispatch(sessionActions.destroy())

    this.setState({
      showLogin: false,
    })
  }

  toggleLogin() {
    const { showLogin } = this.state

    this.setState({
      showLogin: !showLogin,
    })
  }

  handleLoginSubmit() {
    const { isAuthenticated } = this.props

    if (isAuthenticated) {
      this.setState({
        showLogin: false,
      })
    }
  }

  handleLangChange = () => {
    const { isAuthenticated } = this.props
    const { dispatch } = store

    const newLang = this.props.activeLanguage.code === 'en' ? 'fr' : 'en'
    const cookieValue = newLang === 'en' ? '1' : '2'

    document.cookie =
      'p1000Lang=' +
      cookieValue +
      '; path=/; secure; domain=.shredconcussions.ca'

    if (isAuthenticated) {
      dispatch(userActions.update({ language: newLang }, this.props.translate))
    }

    this.props.setActiveLanguage(newLang)
  }

  render() {
    const { isAuthenticated, username } = this.props
    const { showLogin } = this.state

    const languages = this.props.languages.map(el => {
      const isActive = el.code === this.props.activeLanguage.code
      return (
        <li className="mr-2" key={el.code}>
          <span
            className={isActive ? 'btn-active-lang' : 'btn-custom'}
            onClick={isActive ? null : this.handleLangChange}
          >
            {isActive ? (
              <b>{el.code.toUpperCase()}</b>
            ) : (
              <small>{el.code.toUpperCase()}</small>
            )}
          </span>
        </li>
      )
    })

    return (
      <div>
        <LoginModal
          showModal={showLogin}
          isAuthenticated={isAuthenticated}
          onClose={() => this.toggleLogin()}
          onSubmit={() => this.handleLoginSubmit()}
        />
        <div className="topbar-wrapper">
          <div className="container">
            <div className="topbar">
              <div>
                <span className="tablet-hidden topbar-widgets-quote">
                  <span
                    className="accent-color-1"
                    style={{ letterSpacing: '1px', paddingRight: '10px' }}
                  >
                    SHRed Consequences of Concussion{' '}
                  </span>
                </span>
                <img
                  alt="sports dots"
                  style={{ height: '22px', paddingRight: '10px' }}
                  src={dots}
                />
              </div>
              <div>
                <ul className="topbar-widgets">
                  {!isAuthenticated ? (
                    <ul className="topbar-widgets">
                      <li className="mr-2">
                        <span className="btn-custom" onClick={this.toggleLogin}>
                          <Translate id="topBar.login" />
                        </span>
                      </li>
                        <li className="mr-2">
                        <span className="btn-custom">
                          <Link to='account-request'>Signup</Link>
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <ul className="topbar-widgets">
                      <li className="mr-2">
                        <Link className="btn-custom" to="/profile/settings">
                          <Translate id="topBar.settings" />
                        </Link>
                      </li>
                      <li>
                        <span
                          className="btn-custom"
                          onClick={this.handleLogout}
                        >
                          <Translate id="topBar.logout" />
                        </span>
                      </li>
                    </ul>
                  )}
                  <ul className="topbar-widgets">
                    {isAuthenticated && (
                      <ul className="topbar-widgets">
                        <li className="mr-2"></li>
                        <li className="mr-2">
                          <span className="muted-color">{username}</span>
                        </li>
                      </ul>
                    )}
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { isAuthenticated, username } = state.session

  return {
    isAuthenticated,
    username,
  }
}

export default withLocalize(connect(mapStateToProps)(Topbar))
