import React from 'react'
import DatePicker from 'react-datepicker'
import header from '../../images/Consent/consentBannerpng.png'

export class MainConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      share_findings_with_me: null,
      agree_to_be_contacted: false,
      agree_to_participate: false,
    }
  }

  validateComplete = () => {
    const { agree_to_participate, share_findings_with_me } = this.state

    let data = this.state

    if (agree_to_participate && share_findings_with_me !== null) {
      data.formValid = true
    } else {
      data.formValid = false
    }

    this.props.handleChange(data)
  }

  handleChange = event => {
    const { name, value } = event.target
    if (['agree_to_participate', 'agree_to_be_contacted'].includes(name)) {
      this.setState({ [name]: event.target.checked }, this.validateComplete)
    } else if (['share_findings_with_me'].includes(name)) {
      this.setState({ [name]: value === 'true' }, this.validateComplete)
    }
  }

  render() {
    return (
      <div>
        <img
          src={header}
          alt="header"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '1rem',
            width: '100%',
          }}
        />
        <h5>CONSENT FORM</h5>
        <br />
        <h5>
          TITLE: SHRed Concussions and their Consequences in Youth Sport
          <br />
          (Surveillance in High School and Community Sport to Reduce Concussions
          and their Consequences)
        </h5>
        <br />
        <h5>
          FUNDING: Canada Research Chair in Concussion (PI C Emery), Canadian
          Institutes for Health Research Foundation Grant (PI C Emery)
        </h5>

        <h5 style={{ marginTop: '2rem' }}>INVESTIGATORS</h5>
        <p>
          Dr. Carolyn Emery PT PhD (PI), Dr. Olivia Galea PT PhD (postdoctoral
          fellow), Dr Keith Yeates PhD, Dr. Kathryn Schneider PT PhD, Dr.
          Jonathan Smirl PhD, Dr Brian Books PhD, Dr. Daniel Kopala Sibley PhD,
          Dr. Chantel Debert MD MSc, Dr. Ashley Harris PhD, Dr. Bradley Goodyear
          PhD, Dr. Jeff Dunn PhD, Dr. Lianne Tomfohr-Madsen PhD, Dr. Sean
          Dukelow MD PhD, Dr. Tyler Cluff PhD, Dr. Deborah Marshall PhD, Dr.
          Gillian Currie PhD, Dr. Patricia Doyle-Baker PhD, Dr. Jean-Michel
          Galarneau, Dr. Delowar Hossain
          <br />
          <br />
          Students: Ms. Lauren Miutz, Mr. Joel Burma, Mr. Joseph Carere, Ms.
          Courtney Kennedy, Ms. Kirsten Holte, Ms. Melanie Scholz, Mr. Ben
          Leggett, Ms. Carolyn Graham, Ms. Emily Faris, Ms. Jacalyn Moore.
        </p>
        <small>
          <i>
            This consent form is only part of the process of informed consent.
            It should give you the basic idea of what the research is about and
            what your participation will involve. If you would like more detail
            about something mentioned here, or information not included here,
            please ask. Take the time to read this carefully and to understand
            any accompanying information. You will receive a copy of this form.
          </i>
        </small>
        <h5 style={{ marginTop: '2rem' }}>BACKGROUND</h5>
        <p>
          Sport related concussions are among the leading sport and
          recreation-related injuries experienced by youth and account for over
          50% of all youth concussions in Canada. Concussion can have
          longer-term consequences in some individuals leading to absence from
          sport, school and work. Persistent symptoms following concussion may
          include headaches, dizziness, neck pain, limitations in concentration,
          reduced exercise capacity, altered eye motion, mood difficulties, and
          increased health care resource use. Although short-term effects of
          concussion have been investigated in youth, little is known regarding
          potential longer-term consequences. Further knowledge regarding
          potential long-term consequences of concussion will inform a better
          understanding of factors influencing recovery and potential treatment
          strategies. There is a critical need for research examining
          longer-term health outcomes following concussion. You have been asked
          to participate in this study as you may have experienced a
          sport-related concussion or other injury in your youth, or because you
          participated in sport in your youth and did not sustain any
          significant injury. 390 participants are expected to participate in
          this study
        </p>
        <h5>WHAT IS THE PURPOSE OF THE STUDY?</h5>
        <p>
          The purpose of this study is to determine whether or not youth and
          young adults with a history of sport-related concussion or injury in
          the past 5-15 years differ in symptoms, concentration, brain function,
          physical performance, body composition, exercise capacity, activity
          levels, neck function, balance, eye motion, mood, and utilization of
          health care resources compared to youth and young adults with no
          history of injury.
        </p>
        <h5>WHAT WOULD I HAVE TO DO?</h5>
        <p>
          If you choose to participate in the study you will undergo a short
          phone interview (10-15 minutes) with research team member to determine
          if you are eligible and willing to consent to participate in the
          study. Testing will take place in the Sport Injury Prevention Research
          Centre (Faculty of Kinesiology) and Calgary Foothills Medical Centre
          (imaging, and possibly eye hand coordination and blood flow/heart rate
          measures). After you have agreed to receive information about the
          study, you will be sent a link by email to the SHRed Consequences of
          Concussion website portal which provides access to the consent form
          and questionnaires for you to complete. You will need a secure
          internet connection and your own computer/phone to complete the
          consent form and questionnaires. Questionnaires will be related to
          mood and/or symptoms of anxiety, depression and subjective quality of
          life. The baseline questionnaire contains questions related to sex and
          gender identity, sexual orientation, pre-existing medical conditions,
          and health care utilization including hospital stays. The
          questionnaires and cognitive task measure (Cambridge Brain Sciences)
          will take approximately 90 minutes to complete and will include:
          <ol>
            <li>
              Baseline questionnaire (demographics, sport participation,
              healthcare utilization, medical history)
            </li>
            <li>
              Cambridge Brain Science Neurocognitive Assessment including 12
              cognitive tasks designed to examine your concentration, thinking,
              and memory.
            </li>
            <li>PROMIS Cognitive Function - Abilities Short Form 8a</li>
            <li>Godin Leisure-Time Physical Activity Questionnaire (GLTPAQ)</li>
            <li>Fatigue Severity Scale (FSS)</li>
            <li>Insomnia Severity Index (ISI)</li>
            <li>PROMIS Sleep Related Impairment questionnaire</li>
            <li>EuroQol 5 Dimension Questionnaire (EQ-5D-5L)</li>
            <li>The Personal Health Questionnaire – 9 (PHQ-9)</li>
            <li>Generalised Anxiety Disorder 7 (GAD-7)</li>
            <li>Athletic Identity Measurement Scale (AIMS)</li>
            <li>Exercise Identity Scale (EIS)</li>
            <li>Headache Impact Test (HIT-6)</li>
          </ol>
        </p>
        <p>
          You will also be asked to complete some assessment components
          virtually over Zoom (Sport Concussion Assessment Tool 5 (SCAT5) – see
          6a below) following which you will be scheduled to come into the Sport
          Injury Prevention Research Centre, Faculty of Kinesiology, University
          of Calgary for a testing session, which will be approximately 3-hours
          in length (if COVID 19 restrictions allow). You will be provided with
          precise directions and appointment times that suit yourself and the
          researchers. Upon arrival at the Faculty of Kinesiology (Sport
          Medicine Centre entrance, 376 Collegiate Blvd, Calgary), you will be
          met by a researcher who will provide you with a parking pass and
          instructions for parking. You will then be asked to participate in a
          variety of functional and clinical tests. Testing components will
          include:
        </p>
        <p>
          <ol>
            <li>Height, weight, and waist circumference</li>
            <li>
              Dual-energy X-ray absorptiometry scan (DEXA): For the scan you
              will be asked to lay flat on an exam table while the arm of a
              machine passes over you from head to toe to measure your fat and
              muscle mass. This test is an x-ray. It will take about 10 minutes
              and should not give you any discomfort. If you are pregnant at the
              time you will not be eligible for the DEXA study.
            </li>
            <li>
              KINARM testing: This test uses robot technology to examine
              different elements of body awareness and thinking. For these tasks
              you will sit at the KINARM robot and grasp the robotic arms in
              order to perform a series of tasks while watching a scene
              projected onto a two-dimensional virtual reality display. This
              testing may be conducted at the Foothills Medical Centre in
              addition to imaging and blood flow/heart rate measures.
            </li>
            <li>
              Funtional Gait Assessment (FGA). This test will require you to
              perform a series of 10 tasks while walking a short distance and
              examines performance of each of these individually to obtain a
              total score. Tasks to perform while walking include turning your
              head left and right, or up and down, walking quickly and slowly
              etc
            </li>
            <li>
              Walking While Talking Test requires you to walk a short distance
              while performing simple and more difficult mental tasks.
            </li>
            <li>
              Clinical tests
              <ol type={'a'}>
                <li>
                  <i>
                    Sport Concussion Assessment Tool 5 (if not already completed
                    online)
                  </i>{' '}
                  involves completing a symptom scale and performing a series of
                  mental and balance tasks such as walking along a line on the
                  floor with a heel-toe walking style or balancing in different
                  stances with your eyes closed. Your neck will also be briefly
                  examined.
                </li>
                <li>
                  <i>Head Thrust Test</i> involves the examiner holding your
                  head while you are seated and quickly moving it to the left
                  and right while you try to maintain focus of your eyes
                  straight ahead.
                </li>
                <li>
                  <i>Dynamic Visual Acuity and Gaze Stability Testing</i>{' '}
                  involves sitting on a chair at a distance from the wall/ a
                  computer screen and reading aloud a series of letters
                  presented on a chart or the screen. This is done with your
                  head still and also while the examiner moves your head side to
                  side in time to a beat
                </li>
                <li>
                  <i>Vestibular Oculo-Motor Screening</i> involves a series of
                  tests where you will be asked to move your eyes in certain
                  directions while reporting on any symptoms these movements may
                  cause. You will perform these tests in sitting mostly, with
                  one test requiring you to stand and rotate your trunk right to
                  left at a moderate pace.
                </li>
                <li>
                  <i>Cervical Active Range of Motion</i> requires you to wear a
                  halo device on your head and move your head and neck in
                  different direction. The halo is attached to the
                  Multi-cervical unit which records the amount you are able to
                  move your neck.
                </li>
                <li>
                  <i>Cervical Flexion Rotation Test</i> is performed with you
                  lying on a medical bed. The examiner will lift your head as
                  far as it can go (or as far as is comfortable) and gently turn
                  it to the right and left. The test will not be performed if
                  your neck is too sore to tolerate full range of motion.
                </li>
                <li>
                  <i>Cervical Flexor Endurance Test</i> will be performed with
                  you lying on a medical bed. You will be asked to lift your
                  head off the pillow and then hold in this position for as long
                  as you can tolerate while maintaining the same position, and
                  not letting your head lower back down onto the bed.
                </li>
                <li>
                  <i>Cervical Extensor Endurance Test</i> involves lying on the
                  medical bed on your stomach. You will position your head over
                  the edge of the bed so that it is unsupported. You will hold
                  this position as long as you can manage without letting your
                  head lower down toward the floor. This test may cause mild
                  discomfort in your neck muscles related to fatigue.
                </li>
                <li>
                  <i>
                    Cervical Flexor, Extensor and Anterolateral Strength Test
                  </i>{' '}
                  involves you sitting and wearing the halo device used to
                  measure movement of your neck, or lying on the medical bed. In
                  these positions you will be asked to resist a moderate to
                  maximal force applied to different areas of your head for
                  short durations. This will be repeated three times at each
                  location. You will be given a short rest between each
                  repetition.
                </li>
                <li>
                  <i>Cervical Joint Position Error</i> is performed in sitting.
                  You will wear a low-energy laser on your head with the beam
                  focused on a target 90cm in front of you. With your eyes
                  closed you will be asked to find certain positions on the
                  target.
                </li>
                <li>
                  <i>Head Perturbation Test</i> is performed in sitting. The
                  examiner will apply small amounts of pressure with their
                  finger 3 times in random sequence to different parts of your
                  head. You will be asked to resist each pressure applied and
                  the test will be scored based on how well you are able to do
                  this.
                </li>
                <li>
                  <i>Cervical Spine Manual Assessment</i> is performed with you
                  lying on your stomach. The examiner will examine the joints in
                  your neck by pressing gently downwards along the back and
                  sides of your spine from the base of your skull to the top of
                  your shoulder on the right and the left. You will be asked to
                  report how each joint feels as it is pressed.
                </li>
              </ol>
            </li>
            <li>
              <p>
                Aerobic fitness test (aproximately 8-12 minutes): Prior to
                completion of the test, you will be asked to complete a Physical
                Activity Readiness Questionnaire (PARQ+), a screening test to
                ensure that you have no contraindications to completing an
                aerobic fitness test. For this test, you’ll be asked to cycle on
                a bike for as long as you can. While you are cycling the
                resistance on the bike will be increased. The test ends when you
                can no longer keep cycling, are too out of breath or fatigued,
                or experience an increase in symptoms beyond an acceptable
                amount. Before, after and for each minute during the test you
                will be asked to rate your symptoms. For each minute you will
                also be asked to rate your level of effort. During the test you
                will breathe in and out of a mouthpiece. This allows levels of
                certain gases in your breath to be monitored as you exercise.
                Before, during, and after the test you will also be asked to
                wear a heart rate monitor (electrocardiogram, attached to your
                chest using small adhesive electrodes), a cap on your head like
                a swimming cap (fitted with light-emitting diodes, and an
                ultrasound to measure different nervous system functions such as
                blood flow to your brain), and a small tube on your finger (to
                measure your blood pressure). Measures using the cap and finger
                tube may be performed at the Foothills Medical Centre in
                addition to KINARM and MRI. In the event there is an equipment
                malfunction or error, and data collection was not able to be
                collected in a complete manner, you may be asked to return to
                the Sport Injury Prevention Research Centre (Faculty of
                Kinesiology) to repeat the fitness test procedures.
              </p>
              <p>
                <b>NOTE: </b>
                <u>
                  PLEASE LET US KNOW IF YOU HAVE AN ALLERGY TO ADHESIVES.{' '}
                </u>{' '}
                IF SO, THE HEART RATE MONITOR MAY NEED TO BE MODIFIED TO ONE NOT
                REQUIRING THE USE OF ELECTRODES. ALSO, FOR ELECTROCARDIOGRAPHY
                MEASUREMENTS, SELECTED SPOTS ON YOUR CHEST MAY HAVE TO BE SHAVED
                AND SLIGHTLY ABRADED TO APPLY THE MEASUREMENT SENSORS.
              </p>
            </li>
            <li>
              Activity Monitor: You will be asked to participate in a physical
              activity monitoring assessment using an accelerometer device
              (ActiGraph GT3x, GT9x). Specifically, you will be asked to wear
              small, lightweight devices attached via an elastic belt around
              your waist, and via a wrist strap to your wrist for a period of 7
              days and complete a daily sleep log.
            </li>
            <li>
              You will be asked also to participate in magnetic resonance
              imaging (MRI) at the Foothills Medical Centre. You will be asked
              to undergo a one-hour magnetic resonance imaging (MRI) session at
              the Foothills Medical Centre. This is to help examine structure
              and function of your brain. Scientists look for the presence of
              brain biomarkers that may inform brain function and can be
              identified by the scan. Only one series of MRI scans will be done
              by a medical professional skilled in performance of MRI who will
              also complete the standard safety screening with you to ensure MRI
              is a suitable technique. For example, MRI may be inappropriate for
              you if you are pregnant, have certain metal objects (e.g., braces)
              inside your body, or are too claustrophobic to enter the scanner.
              The MRI scan will require you to lie on your back inside an MRI
              scanner for about 40 minutes while scans of your head are
              performed. You will be holding a button, and if you are
              uncomfortable for any reason you can push the button to
              communicate with a technician. If you cannot lie still enough for
              us to perform a high-quality scan, are uncomfortable or anxious
              while in the MR scanner, or you want to stop for any reason you
              can be removed from the scanner immediately. You will not be given
              a sedative or injected with any intravenous contrast material.
            </li>
          </ol>
        </p>
        <h5>WHAT ARE THE RISKS?</h5>
        <p>
          There are no expected risks associated with participating in this
          study. The in-person measurements described above will be done under
          close supervision and every effort will be made to ensure your safety.
          As with any physical activity there is the possibility of a muscle
          pull or strain, or soreness the next day from the endurance tests.
          Additionally, some of the tests of concentration or for neck and eye
          function or balance may result in you feeling mild symptoms such as
          dizziness, headache, nausea, or mental fog. These symptoms usually
          subside quickly once the test is complete and you may discontinue any
          test at any time. The risk of injury will be reduced by careful
          supervision during the testing procedures. Although you will only be
          asked to complete an aerobic fitness test following screening
          (PAR-Q+), it is possible that you may have symptoms of light
          headedness or nausea and this test can be discontinued at any time.
        </p>

        <p>
          As some of the research activities will be completed in-person, it is
          important to recognize any risks that may be associated with the
          COVID-19 pandemic. When participants are visiting the Faculty of
          Kinesiology, University of Calgary for these research activities,
          there may be some additional risks, including:
          <ul>
            <li>Increased exposure to other people (i.e., lab personnel)</li>
            <li>Risks associated with travel (i.e., public transit)</li>
          </ul>
          The research team will take all precautions, including the following,
          to mitigate the possibility of transmission:
          <ul>
            <li>Use of secure remote communication methods</li>
            <li>
              COVID19 screening for study participants and study personnel
              before attending in-person research activities
            </li>
            <li>
              Use/provision of personal protective equipment for research
              participants and study personnel (i.e., masks, gloves)
            </li>
            <li>
              Provision of hand sanitizer for research participants and study
              personnel
            </li>
            <li>
              Single use research apparatus where possible (e.g., paper pillow
              and medical bed face hole covers)
            </li>
            <li>
              Sanitization of surfaces and multi-use equipment between
              participants
            </li>
            <li>
              Physical distancing measures of at least 2 metres whenever
              possible
            </li>
          </ul>
        </p>

        <p>
          The estimated dose of radiation from the DEXA scan is less than 25
          mrads. No amount of radiation is completely safe. For the sake of
          comparison, the dose from a chest x-ray is 25 mrads, from a dental
          x-ray is 750 mrads, natural living at sea level exposes you to 100
          mrads and watching TV one hour per day exposes a person to 1 mrad per
          year. The actual health risks from exposure to low x-ray doses are
          difficult to determine.
        </p>

        <p>
          Magnetic resonance (MR) is a technique that uses magnets and radio
          waves, not radiation, to take pictures of the body. MRI has no known
          harmful effects if you have none of the risk factors, which you will
          be screened for in the pre-MRI screening interview. Specifically, you
          should not have an MRI if you have a pacemaker or certain other metal
          objects inside your body, especially around the eyes, because the
          strong magnets in the MR scanner might cause these to heat up or move,
          causing harm. You will also need to remove all metal from your
          clothing and pockets; otherwise, these objects could be pulled into
          the magnet and cause harm. No metal can be brought into the magnet
          room at any time since the magnet is always "on".
        </p>

        <p>
          Transcranial Doppler ultrasound measures the speed of blood flow in
          deep brain blood vessels and uses sound waves to do so. Functional
          near-infrared spectroscopy measures the amount of oxygen within the
          blood in the outer regions of the brain and uses light to do so. These
          measures are commonly used in research and do not utilize radiation
          for image collection.
        </p>

        <p>
          There may be some initial discomfort wearing the physical activity
          monitor and belt. However, the belt is fully adjustable to fit the
          individual and minimize any discomfort. It may be necessary to shave
          where the ECG electrodes are attached to your chest. It might take a
          few weeks for your hair to grow back at these spots.
        </p>
        <p>
          In case of medical emergencies, standard Faculty of Kinesiology
          protocols will be followed which include calling Campus Security
          (403-250-5333) and 911 if required.
        </p>

        <h5>WHAT IF RESEARCHERS DISCOVER SOMETHING ABOUT ME?</h5>
        <p>
          In the unlikely scenario that a researcher observes a suspected
          abnormality in your results (i.e., images), a study physician will be
          consulted who will decide on the potential significance to your
          health. If considered potentially clinically relevant, you will be
          informed and recommendations for follow-up will be made.
        </p>

        <h5>ARE THERE ANY BENEFITS?</h5>

        <p>
          If you agree to participate in this study, there may or may not be a
          direct medical benefit to you. It is unlikely that your risk of
          experiencing any long-term difficulties in relation to your SRC will
          decrease because of participating in the study. However, the
          information we get from this study may help us to provide better
          injury management and prevention in the future through adolescent
          programs and sport. You will receive information about your cognitive
          tasks (Cambridge brain Sciences), body mass index, bone mineral
          density, % fat and lean body mass, aerobic fitness, neck, balance, and
          oculomotor function as well as the results of your MRI. If you are
          experiencing any persistent symptoms following your SRC these results
          may be useful to identify which modes of treatment might be helpful to
          you
        </p>

        <h5>DO I HAVE TO PARTICIPATE?</h5>

        <p>
          No, you do not have to participate. Participation is completely
          voluntary, and you may withdraw from the study at any time by
          contacting the Research Coordinator. If you withdraw from the study,
          you may request to withdraw your data from the study. Please be
          advised that you will not be able to withdraw your data once data
          analysis has begun. Data collected in person will usually be analyzed
          within one week of collection.
        </p>

        <p>
          Continued participation should be as informed as your initial consent,
          so feel free to ask for clarification or new information throughout
          your participation. If there is new information available throughout
          this study period, you will be informed as soon as possible.
        </p>

        <h5>
          WILL I BE PAID FOR PARTICIPATING, OR DO I HAVE TO PAY FOR ANYTHING?
        </h5>

        <p>
          When you complete the study protocol, you will be offered a $50.00
          gift card of your choice for participating in the study. There will be
          no costs to the participants. Parking at both the Sport Injury
          Prevention Research Centre and Calgary Foothills Medical Centre will
          be provided to you.
        </p>

        <h5>WILL MY RECORDS BE KEPT PRIVATE?</h5>

        <p>
          All the information collected will remain strictly confidential. Your
          privacy will be assured. Only the investigators responsible for this
          study, the research assistants who will be doing the assessments and
          data analysis, and the University of Calgary Conjoint Health Research
          Ethics Board will have access to your identifiable information.
          Confidentiality will be protected by using a study identification
          number in the database. Any results reported from the study will in no
          way identify study participants.
        </p>

        <p>
          REDCap is a web-based electronic data capture (EDC) solution with
          servers located under Canadian jurisdiction. All data are encrypted
          and stored directly on its servers. Researcher access to the survey
          data is a combination of role-based access, strict password management
          processes, and two-factor authentication. Survey responses cannot be
          linked to your computer. Test results for the Cambridge Brain Science
          cognitive assessment completed via the REDCap platform is collected
          under your unique identification number.
        </p>

        <h5>IF I SUFFER A RESEARCH-RELATED INJURY, WILL I BE COMPENSATED?</h5>

        <p>
          If you suffer an injury as a result of participating in this research,
          the Sport Injury Prevention Research Centre, the University of Calgary
          or the Researchers will provide no compensation to you. You still have
          all your legal rights. Nothing said in this consent form alters your
          rights to seek damages.
        </p>

        <h5>SIGNATURES</h5>
        <p>
          Your signature on this form indicates that you have understood to your
          satisfaction the information regarding your participation in the
          research project and agree to participate as a participant. In no way
          does this waive your legal rights nor release the investigators or
          involved institutions from their legal and professional
          responsibilities. You are free to withdraw from the study at any time
          without jeopardizing your health. If you have further questions
          concerning matters related to this research, please contact:
        </p>

        <p>
          Research Coordinator at 403-220-3394 or Dr. Carolyn Emery (Primary
          Investigator) at 403-220-4608
        </p>

        <p>
          If you have any questions concerning your rights as a possible
          participant in this research, or research in general, please contact
          the Chair of the Conjoint Health Research Ethics Board, University of
          Calgary, at 403-220-7990. The University of Calgary Conjoint Health
          Research Ethics Board has approved this research study (REB21-0548).
        </p>

        <p>
          If appropriate, a research coordinator may contact you about other
          opportunities to participant in related research projects. Additional
          ethics approval from a Research Ethics Board will be obtained for any
          future studies. Your decision to participate in these related projects
          will not in any way influence or affect your involvement in this
          study.
        </p>

        <p>I consent for the researchers to share findings with me:</p>

        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              name="share_findings_with_me"
              id="share_findings_with_me_yes"
              value={true}
              onClick={this.handleChange}
            />
            Yes
          </label>
        </div>
        <div className="form-check" style={{ marginBottom: '1rem' }}>
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              name="share_findings_with_me"
              id="share_findings_with_me_no"
              value={false}
              onClick={this.handleChange}
            />
            No
          </label>
        </div>

        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label"></label>
          <div className={'col-lg-12 mb-2'}>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label style={{ display: 'block' }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="agree_to_be_contacted"
                  id="agree_to_be_contacted"
                  onClick={this.handleChange}
                />
              </label>
              <label>
                By checking this box, I agree to be contacted about
                opportunities to participate in related research projects.
              </label>
            </div>
          </div>
        </div>

        <p>
          By checking this box and typing my name below, I am electronically
          signing this consent form.
        </p>

        <div className={'form-group row'}>
          <label className="col-lg-4 col-form-label"></label>
          <div className={'col-lg-12 mb-2'}>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <label style={{ display: 'block' }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="agree_to_participate"
                  id="agree_to_participate"
                  onClick={this.handleChange}
                />
              </label>
              <label>I agree to participate in this study</label>
            </div>
          </div>
        </div>
        <hr />
      </div>
    )
  }
}
