import { CancelToken } from 'axios'

import { handleAxiosError } from '../helpers'
import { instance } from '../helpers'

export const euroQolServices = {
  get,
  post,
}

function get(cancel) {
  return instance
    .get('/euroqol', { cancelToken: new CancelToken(c => (cancel.exec = c)) })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}

function post(params) {
  return instance
    .post('/euroqol', params)
    .then(response => {
      return response
    })
    .catch(error => {
      return handleAxiosError(error)
    })
}
