import React from 'react'
import { push } from 'react-router-redux'
import { withLocalize } from 'react-localize-redux'

import { store } from '../../helpers/index'
import { alertActions } from '../../actions/index'
import { FormErrors } from '../../components/FormErrors'
import { activateServices } from '../../services'
import ConsentFormModal from '../../components/Modals/ConsentFormModal'

let cancel = { exec: null }

class ActivateChild extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      password_confirmation: '',
      city: '',
      signature: '',
      terms: false,
      future_research: false,
      consent_content: {},
      formErrors: { password: '', password_confirmation: '', terms: '' },
      passwordValid: false,
      passwordConfirmationValid: false,
      termsValid: false,
      formValid: false,
      showConsentModal: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleConsentModalAccept = this.handleConsentModalAccept.bind(this)
    this.toggleConsentModal = this.toggleConsentModal.bind(this)
  }

  componentDidMount() {
    const { dispatch } = store
    const { token } = this.props.match.params

    activateServices.get(token, cancel).then(
      response => {
        const data = response.data

        this.setState({
          city: data['city'],
        })
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  componentWillUnmount() {
    if (cancel.exec) cancel.exec()
  }

  handleChange(event) {
    const { target } = event
    const { name, type } = target

    let value = type === 'checkbox' ? target.checked : target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const {
      formValid,
      termsValid,
      password,
      password_confirmation,
      signature,
      terms,
      future_research,
      consent_content,
    } = this.state
    const { dispatch } = store

    if (formValid && termsValid) {
      const { token } = this.props.match.params
      const params = {
        password,
        password_confirmation,
        signature,
        terms,
        future_research,
        consent_content,
      }
      const { translate } = this.props

      activateServices.create(params, token).then(
        response => {
          dispatch(push('/'))
          dispatch(alertActions.success(translate('alert.activate')))
        },
        error => {
          if (error.response) {
            const errors = error.response.data.errors
            if (errors) {
              errors.forEach(e => {
                dispatch(alertActions.error(e))
              })
            }
          }
        }
      )
    } else if (formValid && !termsValid) this.toggleConsentModal()
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let { passwordValid, passwordConfirmationValid, termsValid } = this.state
    const { password, password_confirmation } = this.state
    const { translate } = this.props

    switch (fieldName) {
      case 'password':
        if (value !== '') {
          passwordValid = value.length >= 6
          fieldValidationErrors.password = passwordValid
            ? ''
            : translate('activateChild.form.errors.password.min')
          if (passwordValid) {
            passwordConfirmationValid =
              value === password_confirmation &&
              password_confirmation.length > 0
            fieldValidationErrors.password_confirmation = passwordConfirmationValid
              ? ''
              : translate('activateChild.form.errors.password.match')
          }
        } else {
          passwordValid = false
          passwordConfirmationValid = false
          fieldValidationErrors.password = ''
          fieldValidationErrors.password_confirmation = ''
        }
        break
      case 'password_confirmation':
        if (value !== '') {
          passwordConfirmationValid = value === password
          fieldValidationErrors.password_confirmation = passwordConfirmationValid
            ? ''
            : translate('activateChild.form.errors.password.match')
        } else passwordConfirmationValid = false
        if (passwordValid)
          fieldValidationErrors.password_confirmation = passwordConfirmationValid
            ? ''
            : translate('activateChild.form.errors.password.match')
        else fieldValidationErrors.password_confirmation = ''
        break
      case 'terms':
        termsValid = value === true
        fieldValidationErrors.terms = termsValid
          ? ''
          : translate('activateChild.form.errors.terms')
        break
      default:
        break
    }

    this.setState(
      {
        passwordValid: passwordValid,
        passwordConfirmationValid: passwordConfirmationValid,
        termsValid: termsValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    )
  }

  validateForm() {
    const { passwordValid, passwordConfirmationValid } = this.state
    this.setState({
      formValid: passwordValid && passwordConfirmationValid,
    })
  }

  toggleConsentModal() {
    const { showConsentModal } = this.state

    this.setState({
      showConsentModal: !showConsentModal,
    })
  }

  handleConsentModalAccept(signature, future_research, consent_content) {
    let fieldValidationErrors = this.state.formErrors
    fieldValidationErrors.terms = ''

    this.setState(
      {
        signature: signature,
        showConsentModal: false,
        terms: !!signature,
        termsValid: !!signature,
        formErrors: fieldValidationErrors,
        future_research: future_research,
        consent_content: consent_content,
      },
      this.validateForm
    )
  }

  render() {
    const {
      formErrors,
      formValid,
      password,
      password_confirmation,
      showConsentModal,
      city,
    } = this.state

    const { translate } = this.props

    return (
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-9">
            <h2 className="heading">
              {translate('activateChild.header.activate')}{' '}
              <span className="text-primary">
                {translate('activateChild.header.account')}
              </span>
            </h2>
            <div className="mt-2 mb-2">
              <FormErrors formErrors={formErrors} />
            </div>
            <form name="form" onSubmit={this.handleSubmit}>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">
                  {translate('activateChild.form.label.password')}
                </label>
                <div className="col-lg-6 mb-2">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">
                  {translate('activateChild.form.label.confirmation')}
                </label>
                <div className="col-lg-6 mb-2">
                  <input
                    type="password"
                    className="form-control"
                    name="password_confirmation"
                    value={password_confirmation}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-check mt-5">
                <span className="lead">
                  <span style={{ color: '#D32F2F' }}>*</span>
                  {translate('activateChild.form.label.terms.message')}
                  <span
                    className="btn-custom"
                    onClick={this.toggleConsentModal}
                  >
                    {translate('activateChild.form.label.terms.link')}
                  </span>
                </span>
              </div>
              <button
                type="submit"
                name="submit"
                className="btn btn-primary mt-5"
                disabled={!formValid}
              >
                {translate('activateChild.form.label.submit')}
              </button>
            </form>
          </div>
        </div>
        <ConsentFormModal
          showModal={showConsentModal}
          signatureAllowed={true}
          city={city}
          role={'child'}
          onClose={this.toggleConsentModal}
          onAccept={this.handleConsentModalAccept}
        />
      </div>
    )
  }
}

export default withLocalize(ActivateChild)
