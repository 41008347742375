import React from 'react'
import {push} from 'react-router-redux'
import {connect} from 'react-redux'
import {Translate, withLocalize} from 'react-localize-redux'

import {store} from '../../helpers'

export class About extends React.Component {
    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(event) {
        event.preventDefault()
        const {dispatch} = store

        dispatch(push('/account-request'))
    }

    render() {
        const {isAuthenticated} = this.props

        return (
            <div>
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-9 col-sm-12">
                                    <h2 className="heading mb-5">
                                        AN {' '}
                                        <span className="text-primary">
                                            INTRODUCTION
                                        </span>
                                    </h2>
                                </div>
                                <div className="col-lg-3 col-sm-12 mb-5">
                                    {!isAuthenticated && (
                                        <div className="text-center">
                                            <h5 className="mb-2">
                                                SIGN UP NOW!
                                            </h5>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={this.handleClick}
                                            >
                                                Click here to sign up
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <h5 className="mb-4">
                                The SHRed CONSEQUENCES OF CONCUSSION STUDY
                            </h5>
                            <p className="text-justify mb-5">
                                Thank you for your interest in SHRed Consequences of Concussion (Surveillance in High
                                Schools and Community Sport to Reduce the Risk of Concussion and their Consequences).
                                In North America youth account for over 50% of the three million concussions sustained
                                annually. Following concussion, up to 30% of individuals who are injured may also
                                experience persistent symptoms. Little is known regarding the potential longer-term
                                consequences following concussion. The primary objective of SHRed Consequences of
                                Concussion is to develop a greater understanding of the consequences of concussion, in
                                particular those occurring in relation to playing hockey. Thank you for your important
                                contribution through participation in SHRed Consequences of Concussion
                            </p>
                            <h5 className="mb-4">
                                ETHICS APPROVAL
                            </h5>
                            <p className="mb-5 text-justify">
                                The SHRed Consequences of Concussion study has been approved by the Research Ethics
                                Board at the University of Calgary (REB21-0548)
                            </p>
                            <div className="col-lg-6 col-sm-12 pl-0">
                                <div className="well pl-3 pr-3">
                                    <h5 className={'mb-3 mt-3 text-primary'}>
                                        HOW TO GET STARTED
                                    </h5>
                                    <h5 className={'mb-3 mt-3'}>
                                        Have you already been invited to join the SHRed Consequences of Concussion
                                        Study?
                                    </h5>
                                    <p>
                                        After talking with the SHRed Consequences of Concussion study team you should
                                        receive a text message with a link to create your account.
                                    </p>
                                    <ol>
                                        <li className="mb-1">
                                            Click on the link to create your account
                                            <ul>
                                                <li>Create a password</li>
                                                <li>Read and sign the study consent form.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-1">
                                            Check your email for your account confirmation (double check your junk and
                                            spam folder if you cannot find it).
                                            <ul>
                                                <li>Click on the link in your email to confirm your account.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-1">
                                            You should now be able to sign into your account at {' '}
                                            <a href="https://consequences.shredconcussions.ca">
                                                consequences.shredconcussions.ca
                                            </a>
                                            {' '}and complete your study questions
                                        </li>
                                    </ol>
                                    <h5 className={'mb-3 mt-3'}>
                                        Interested in being part of the study?
                                    </h5>
                                    <p>
                                        If you are interested in participating in the SHRed Consequences of Concussion
                                        Study, please email us at {' '}
                                        <a href="mailto:shredconsequences@ucalgary.ca">SHRedConsequences@ucalgary.ca</a>
                                        {' '} or call us at 403-220-3394 to see if you qualify
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {isAuthenticated} = state.session

    return {
        isAuthenticated,
    }
}

export default withLocalize(connect(mapStateToProps)(About))
