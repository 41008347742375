import React from 'react'

import { Translate } from 'react-localize-redux'

import { store } from '../../../helpers/index'
import { userActions } from '../../../actions/index'
import ConfirmationModal from '../../Modals/ConfirmationModal'

export class Delete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteConfirmationModal: false,
      optoutConfirmationModal: false,
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleOptOut = this.handleOptOut.bind(this)
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
    this.toggleOptoutModal = this.toggleOptoutModal.bind(this)
  }

  handleDelete() {
    const { dispatch } = store

    dispatch(userActions.destroy('type=delete', this.props.translate))
  }

  toggleDeleteModal() {
    const { deleteConfirmationModal } = this.state
    this.setState({
      deleteConfirmationModal: !deleteConfirmationModal,
    })
  }

  toggleOptoutModal() {
    const { optoutConfirmationModal } = this.state
    this.setState({
      optoutConfirmationModal: !optoutConfirmationModal,
    })
  }

  handleOptOut() {
    const { dispatch } = store

    dispatch(userActions.destroy('type=opt_out', this.props.translate))
  }

  render() {
    const { deleteConfirmationModal, optoutConfirmationModal } = this.state
    const { role } = this.props

    return (
      <div className="mb-5">
        <div className="row">
          <div className="col-lg-6">
            <h5>
              <Translate id="deleteAccount.headerDelete" />
            </h5>
            <p>
              <Translate id="deleteAccount.deleteMyAccount" />
            </p>
          </div>
          {role === 'parent' && (
            <div className="col-lg-6">
              <h5>
                <Translate id="deleteAccount.headerOptOut" />
              </h5>
              <p>
                <Translate id="deleteAccount.optOut" />
              </p>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <button
              type="submit"
              className="btn btn-danger"
              onClick={this.toggleDeleteModal}
            >
              <Translate id="deleteAccount.deleteBtn" />
            </button>
          </div>
          {role === 'parent' && (
            <div className="col-lg-6 mb-4">
              <button
                type="submit"
                className="btn btn-danger"
                onClick={this.toggleOptoutModal}
              >
                <Translate id="deleteAccount.optOutBtn" />
              </button>
            </div>
          )}
          <ConfirmationModal
            showModal={deleteConfirmationModal}
            onClose={this.toggleDeleteModal}
            handleAction={this.handleDelete}
            message={this.props.translate('deleteAccount.deleteWarning')}
            action={this.props.translate('deleteAccount.deleteAction')}
          />
          <ConfirmationModal
            showModal={optoutConfirmationModal}
            onClose={this.toggleOptoutModal}
            handleAction={this.handleOptOut}
            message={this.props.translate('deleteAccount.optOutWarning')}
            action={this.props.translate('deleteAccount.optOutActionParent')}
          />
        </div>
      </div>
    )
  }
}
