import React from 'react'
import { Link } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'

import { store } from '../../helpers/index'
import { alertActions } from '../../actions/index'
import { FormErrors } from '../../components/FormErrors'
import { recoverableServices } from '../../services'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      formErrors: { email: '' },
      emailValid: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const { target } = event
    const { name, value } = target
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { emailValid, email } = this.state
    const { dispatch } = store

    if (emailValid && email) {
      recoverableServices.create({ email }).then(
        response => {
          dispatch(
            alertActions.success(this.props.translate('alert.emailSent'))
          )
        },
        error => {
          if (error.response) {
            const errors = error.response.data.errors
            if (errors) {
              errors.forEach(e => {
                dispatch(alertActions.error(e))
              })
            }
          }
        }
      )
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let { emailValid } = this.state

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        fieldValidationErrors.email = emailValid
          ? ''
          : 'Please enter a valid email address.'
        break
      default:
        break
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
    })
  }

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error'
  }

  render() {
    const { email, formErrors, emailValid } = this.state
    const { translate } = this.props

    return (
      <div className="container mt-4 mb-4">
        <div className="row flex-row justify-content-center text-center">
          <div className="col-lg-6">
            <h2 className="heading">
              {translate('forgotPassword.header.forgot')}{' '}
              <span className="text-primary">
                {translate('forgotPassword.header.password')}
              </span>
            </h2>
            <div className="mt-2 mb-2">
              <FormErrors formErrors={formErrors} />
            </div>
            <form name="form" onSubmit={this.handleSubmit}>
              <div
                className={`form-group ${this.errorClass(formErrors.email)}`}
              >
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder={translate('forgotPassword.form.label.email')}
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
              <button
                type="submit"
                name="submit"
                className="btn btn-primary mt-3 mb-2"
                disabled={!emailValid}
              >
                {translate('forgotPassword.form.label.submit')}
              </button>
              <div>
                <Link
                  className="btn-custom"
                  to="/account-request"
                  style={{ fontSize: '14px' }}
                >
                  {translate('login.noAccount')}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default withLocalize(ForgotPassword)
