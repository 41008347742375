import { sessionConstants, recordConstants } from '../constants'
import { recordServices } from '../services'
import { alertActions } from './index'

export const recordsActions = {
  create,
  get,
  update,
  destroy,
  refresh,
}

function create(params, translate) {
  return dispatch => {
    recordServices.create(params).then(
      response => {
        if ('token' in response.data) {
          const token = response.data.token
          sessionStorage.setItem('token', token)
          dispatch(authSuccess(token))
        }
        dispatch(recordsActions.get())
        dispatch(alertActions.success(translate('alerts.childAdded')))
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  //need this to update token values
  function authSuccess(token) {
    return { type: sessionConstants.SESSION_UPDATE_SUCCESS, token }
  }
}

function update(params, translate) {
  return dispatch => {
    recordServices.update(params).then(
      response => {
        dispatch(recordsActions.get())
        dispatch(alertActions.success(translate('alerts.childUpdated')))
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }
}

function get() {
  return dispatch => {
    dispatch(request())
    recordServices.get().then(
      response => {
        const records = response.data.data
        if (records) {
          sessionStorage.setItem('records', JSON.stringify(records))
          dispatch(success(records))
        }
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            dispatch(failure(errors))
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  function request() {
    return { type: recordConstants.RECORD_REQUEST }
  }
  function success(records) {
    return { type: recordConstants.RECORD_SUCCESS, records }
  }
  function failure(errors) {
    return { type: recordConstants.RECORD_FAILURE, errors }
  }
}

function destroy(params, translate) {
  return dispatch => {
    recordServices.destroy(params).then(
      response => {
        dispatch(alertActions.success(translate('alerts.childDeleted')))
        dispatch(recordsActions.get())
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }
}

function refresh(records) {
  return dispatch => {
    sessionStorage.setItem('records', JSON.stringify(records))
    dispatch({ type: recordConstants.RECORD_REFRESH, records })
  }
}
