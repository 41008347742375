import React from 'react'

import { Mail } from 'react-feather'
import { Phone } from 'react-feather'

export const ContactUs = () => (
  <div className="container mt-4 mb-4">
    <div className="row">
      <div className="col-lg-12">
        <h2 className="heading mb-5">
          CONTACT{' '}
          <span className="text-primary">
            US
          </span>
        </h2>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <h5 className="mb-4">
          How to contact us:
        </h5>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <address className="bg-light p-3">
          Study Coordinator: Lisa Loos, B.Kin, RMT
          <br />
          KNB 3300
          <br />
          Faculty of Kinesiology, University of Calgary
          <br />
          2500 University Drive NW
          <br />
          Calgary, Alberta, T2N 1N4
          <br />
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Mail className="mr-2" />
            <a href="mailto:shredconsequences@ucalgary.ca">SHRedConsequences@ucalgary.ca</a>
            <br />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Phone className="mr-2" />
            403-220-3394
          </div>
        </address>
      </div>
      <div className="col-lg-6">
        <address className="bg-light p-3">
          Principal Investigator: Dr. Carolyn Emery
          <br />
          Faculty of Kinesiology, University of Calgary
          <br />
          2500 University Drive NW
          <br />
          Calgary, AB T2N 1N4
          <br />
          <br />
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Mail className="mr-2" />
            <a href="mailto:caemery@ucalgary.ca">caemery@ucalgary.ca</a>
            <br />
          </div>
        </address>
      </div>
    </div>
  </div>
)
