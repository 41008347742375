import React, {useState} from 'react'
import {Transition, animated} from 'react-spring/renderprops'
import {Translate} from 'react-localize-redux'
import {Collapse} from 'reactstrap'
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io'

import Login from '../../components/Account/Login'
import homeJPG from '../../images/Home/Hockey_female1_1-2.jpg'
import homeWEBP from '../../images/Home/Hockey_female1_1-2.webp'
import dot1 from '../../images/Misc/siprc_dot1.png'
import dot2 from '../../images/Misc/siprc_dot2.png'
import dot3 from '../../images/Misc/siprc_dot3.png'
import dot4 from '../../images/Misc/siprc_dot4.png'
import './home.css'
import {Link} from "react-router-dom";

const items = [
    {image: dot1, key: 1},
    {image: dot2, key: 2},
    {image: dot3, key: 3},
    {image: dot4, key: 4},
]

export const Home = props => {
    const {isAuthenticated} = props
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)

    const [isOneOpen, setIsOneOpen] = useState(false)
    const [isTwoOpen, setIsTwoOpen] = useState(false)
    const [isThreeOpen, setIsThreeOpen] = useState(false)

    // This got messy...
    const toggle = selector => {
        if (selector === 'one' && isOneOpen && !isTwoOpen && !isThreeOpen) {
            setIsOneOpen(false)
            return
        } else if (selector === 'two' && isTwoOpen && !isOneOpen && !isThreeOpen) {
            setIsTwoOpen(false)
            return
        } else if (
            selector === 'three' &&
            isThreeOpen &&
            !isOneOpen &&
            !isTwoOpen
        ) {
            setIsThreeOpen(false)
            return
        } else if (!isOneOpen && !isTwoOpen && !isThreeOpen) {
            if (selector === 'one') setIsOneOpen(true)
            else if (selector === 'two') setIsTwoOpen(true)
            else setIsThreeOpen(true)
            return
        } else {
            if (selector === 'one') {
                setIsOneOpen(!isOneOpen)
                setIsTwoOpen(false)
                setIsThreeOpen(false)
            } else if (selector === 'two') {
                setIsTwoOpen(!isTwoOpen)
                setIsOneOpen(false)
                setIsThreeOpen(false)
            } else {
                setIsThreeOpen(!isThreeOpen)
                setIsOneOpen(false)
                setIsTwoOpen(false)
            }
        }
    }

    const getDots = data => {
        const items = []
        data.map(item =>
            items.push(
                <img
                    key={item.key}
                    className="dots dots-mobile m-2"
                    src={item.image}
                    alt="dot_logo"
                />
            )
        )
        return (
            <Transition
                native={true}
                items={items}
                keys={item => item.key}
                from={{
                    transform: 'translate3d(0,-50%,0)',
                    opacity: '0',
                }}
                enter={{
                    transform: 'translate3d(0,0,0)',
                    opacity: '1',
                }}
                trail={175}
            >
                {item => props => <animated.div style={props}>{item}</animated.div>}
            </Transition>
        )
    }

    return (
        <div style={{position: 'relative'}}>
            <picture>
                <source srcSet={homeWEBP} type="image/webp"/>
                <source srcSet={homeJPG} type="image/jpeg"/>
                <img
                    className="home-cover"
                    alt="background"
                    src={homeJPG}
                    style={{
                        width: '100%',
                        objectFit: 'cover',
                        borderRadius: `0px`,
                    }}
                />
            </picture>
            <div
                className="container"
                style={{
                    position: 'absolute',
                    left: '55%',
                    top: '22%',
                    transform: 'translate(-55%, -15vh)',
                    zIndex: 1,
                }}
            >
                <div className="row">{getDots(items)}</div>
                <div className="row">
                    <div
                        className="col-lg-6 p-5 mt-2 card"
                        style={{
                            boxShadow:
                                '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
                        }}
                    >
                        {!isMobile && (
                            <div className="mb-4">
                                <h1 className="heading m-0">
                                    SHRed{' '}
                                    <span className="text-primary">CONSEQUENCES</span>
                                </h1>
                                <span className="lead muted-color">
                                    Developing a greater understanding of the long-term consequences of hockey-related
                                    concussions
                                </span>
                            </div>
                        )}
                        {!isAuthenticated && <Login loginStyle={!isMobile && 'home'}/>}
                        {!isAuthenticated && (
                            <React.Fragment>
                                <hr/>
                                <div style={{maxHeight: '345px', overflowY: 'auto'}}>
                                    <h5 className="text-primary">
                                        <a
                                            style={{textDecoration: 'none'}}
                                            href="#!"
                                            onClick={() => toggle('one')}
                                        >
                                            Why should you join?{' '}
                                            <span class="text-right">
                                                {isOneOpen ? (
                                                    <IoIosArrowUp style={{verticalAlign: 'middle'}}/>
                                                    ) : (
                                                        <IoIosArrowDown style={{verticalAlign: 'middle'}}/>
                                                        )}
                                            </span>
                                        </a>
                                    </h5>
                                    <Collapse isOpen={isOneOpen}>
                                        <ul style={{color: '#665c56'}}>
                                            <li>
                                                <b>If you experience a concussion </b> 5-15 years ago in your youth, you
                                                will have access to follow-up by our concussion team that will evaluate
                                                the potential longer-term effects of your injury
                                            </li>
                                            <li style={{marginTop: '0.5rem'}}>
                                                <b>If you suffered a musculoskeletal injury, </b> you will have access
                                                to follow-up by our injury team that will evaluate the potential
                                                longer-term effects of your injury
                                            </li>
                                            <li style={{marginTop: '0.5rem'}}>
                                                You will be given access following the study to online education tools
                                                including the Massive Open Online Course in Concussion and the
                                                Concussion Awareness Training Tool
                                            </li>
                                            <li style={{marginTop: '0.5rem'}}>
                                                Your participation will make an important contribution to understanding
                                                the potential longer-term consequences of concussion and other injuries
                                                and to ensure safe participation for everyone
                                            </li>
                                        </ul>
                                    </Collapse>
                                    <h5 class="text-primary">
                                        <a
                                            style={{textDecoration: 'none'}}
                                            href="#!"
                                            onClick={() => toggle('two')}
                                        >
                                            What would you need to do?{' '}
                                            {isTwoOpen ? (
                                                <IoIosArrowUp style={{verticalAlign: 'middle'}}/>
                                            ) : (
                                                <IoIosArrowDown style={{verticalAlign: 'middle'}}/>
                                            )}
                                        </a>
                                    </h5>
                                    <Collapse isOpen={isTwoOpen}>
                                        <ul style={{color: '#665c56'}}>
                                            <li>
                                                <Link to="/account-request">Contact us</Link> to register your interest
                                                in participating.
                                            </li>
                                            <li style={{marginTop: '0.5rem'}}>
                                                Complete the online registration and the online questionnaires and tests
                                                (e.g., medical history, Cambridge Brain Sciences, physical activity,
                                                athletic identity, quality of life).
                                            </li>
                                            <li style={{marginTop: '0.5rem'}}>
                                                Participate in a one-time virtual Sport Concussion Assessment Tool 5
                                                (e.g., memory tasks, balance skills) and wear an activity monitor
                                                (Actigraph) used to assess physical activity and sleep for 7 days
                                            </li>
                                            <li style={{marginTop: '0.5rem'}}>
                                                Complete a one-time in-person assessment at the University of Calgary
                                                including clinical measures and exercise testing
                                            </li>
                                            <li style={{marginTop: '0.5rem'}}>
                                                Attend a one-time in-person imaging appointment at Foothills Medical
                                                Center, where you will also be invited to complete additional imaging
                                                and KinArm motor control testing
                                            </li>
                                        </ul>
                                    </Collapse>
                                    <h5 className="text-primary">
                                        <a
                                            style={{textDecoration: 'none'}}
                                            href="#!"
                                            onClick={() => toggle('three')}
                                        >
                                            COVID 19 Modifications{' '}
                                            {isThreeOpen ? (
                                                <IoIosArrowUp style={{verticalAlign: 'middle'}}/>
                                            ) : (
                                                <IoIosArrowDown style={{verticalAlign: 'middle'}}/>
                                            )}
                                        </a>
                                    </h5>
                                    <Collapse isOpen={isThreeOpen}>
                                        <p style={{color: '#665c56'}}>
                                            Thank you for your interest in the SHRed Consequences of Concussion study
                                            which is accepting new participants, amidst this pandemic.
                                        </p>
                                        <p style={{marginTop: '0.5rem', color: '#665c56'}}>
                                            Your health and safety are very important to us. We will be limiting face
                                            to face contact at this time. We will still be recruiting participants, but
                                            this will include online and virtual assessment. Clinical outcomes,
                                            exercise testing, KinArm, and imaging will be completed when possible. We
                                            will follow all measures set out by provincial health authorities to protect
                                            your health and safety.
                                        </p>
                                    </Collapse>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
