import React from 'react'

import ReactModal from 'react-modal'
import Modal from 'react-modal'
import moment from 'moment'
import {XCircle} from 'react-feather'
import {Translate, withLocalize} from 'react-localize-redux'

import './Modal.css'
import {consentForms, consentContentRequired} from '../ConsentForms/ComponentMap'
import {consentFormServices} from '../../services'
import {store} from '../../helpers'
import {alertActions} from '../../actions'
import consentFormTranslations from '../../localize/consentForms.json'

class ConsentFormModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            eSig: '',
            future_research: false,
            consent_content: {},
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleConsentContentChange = this.handleConsentContentChange.bind(this)
        this.printPDF = this.printPDF.bind(this)
        this.checkValid = this.checkValid.bind(this)
        this.props.addTranslation(consentFormTranslations)
    }

    componentDidMount() {
        Modal.setAppElement('body')
    }

    handleChange(event) {
        const {target} = event
        const {name, type} = target

        let value = type === 'checkbox' ? target.checked : target.value
        this.setState({
            [name]: value,
        })
    }

    handleConsentContentChange(consent_content) {
        this.setState({
            consent_content: consent_content,
        })
    }

    printPDF() {
        const {dispatch} = store
        const {city, signature, signatureAllowed, created, role} = this.props
        const {eSig, consent_content} = this.state
        const params = {
            city,
            role,
            signature: signatureAllowed ? eSig : signature,
            consent_content: consent_content ? consent_content : {},
            date: signatureAllowed
                ? moment().format('LL')
                : moment(created).format('LL'),
        }

        consentFormServices.create(params).then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                const fileName =
                    this.props.translate('consentFormModal.consentForm') + '.pdf'
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            },
            error => {
                if (error.response) {
                    const errors = error.response.data.errors
                    if (errors) {
                        errors.forEach(e => {
                            dispatch(alertActions.error(e))
                        })
                    }
                }
            }
        )
    }

    checkValid() {
        const {eSig, consent_content} = this.state

        let valid = false
        let contentRequired = false
        contentRequired = consentContentRequired['MainConsent']

        if (eSig && eSig.trim()) {
            if (contentRequired) {
                if (consent_content.formValid) valid = true
            } else valid = true
        }

        return valid
    }

    render() {
        const {eSig, future_research, consent_content} = this.state
        const {
            showModal,
            signatureAllowed,
            signature,
            created,
            onClose,
            onAccept
        } = this.props

        let ComponentToShow = null
        ComponentToShow = consentForms['MainConsent']

        return (
            <div>
                <ReactModal
                    style={{
                        overlay: {backgroundColor: 'rgba(33, 33, 33, 0.85)'},
                        content: {
                            maxWidth: ComponentToShow ? 1280 : 700,
                            maxHeight: ComponentToShow ? 800 : 250,
                            width: '80%',
                            margin: 'auto',
                        },
                    }}
                    isOpen={showModal}
                    shouldCloseOnEsc={true}
                    shouldReturnFocusAfterClose={true}
                    closeTimeoutMS={200}
                    onRequestClose={() => onClose(eSig)}
                >
                    <XCircle
                        size={32}
                        onClick={() => onClose(eSig)}
                        style={{cursor: 'pointer', float: 'right'}}
                    />
                    <div className="container mt-5">
                        <div>
                            <ComponentToShow
                                translate={this.props.translate}
                                handleChange={this.handleConsentContentChange}
                            />
                            <div className="mt-5">
                                <div>
                                    <div className="row pl-3">
                                        <label className="col-form-label">
                                            <Translate id="consentFormModal.printName"/>:
                                        </label>
                                        <div className="col-lg-5 mb-4">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="eSig"
                                                name="eSig"
                                                value={eSig}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <label className="col-lg-5 col-form-label">
                                            <Translate id="consentFormModal.date"/>:{' '}
                                            {moment().format('LL')}
                                        </label>
                                    </div>
                                    <div
                                        className="form-group"
                                        style={{display: 'flex', alignItems: 'center'}}
                                    >
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                onAccept(eSig, future_research, consent_content)
                                            }
                                            disabled={!this.checkValid()}
                                        >
                                            <Translate id="consentFormModal.acceptBtn"/>
                                        </button>
                                        <span
                                            className="btn-custom ml-3"
                                            onClick={this.printPDF}
                                        >
                                                  <Translate id="consentFormModal.saveAsPDF"/>
                                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </div>
        )
    }
}

export default withLocalize(ConsentFormModal)
