import React from 'react'
import { push } from 'react-router-redux'
import qs from 'qs'
import { withLocalize, Translate } from 'react-localize-redux'

import { store } from '../../../helpers/index'
import { Surveys } from '../../Surveys'
import './Child.css'
import globalTranslations from '../../../localize/global'

let cancel = { exec: null }

class Child extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeTab: 'baseline' }
    this.props.addTranslation(globalTranslations)
  }

  componentDidMount() {
    const { location } = this.props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.contentTab) {
        this.setState({
          activeTab: search.contentTab,
        })
      }
    }
  }

  componentWillUnmount() {
    if (cancel.exec) cancel.exec()
  }

  chunkArrayInGroups = (arr, size) => {
    let myArray = []
    for (let i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i + size))
    }
    return myArray
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.contentTab) {
        state.activeTab = search.contentTab
      }
    }

    return state
  }

  handleTabChange = tab => {
    const { dispatch } = store
    const { location } = this.props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.childTab) {
        dispatch(
          push({
            pathname: '/profile/dashboard',
            search: '?childTab=' + search.childTab + '&contentTab=' + tab,
          })
        )

        this.setState({
          activeTab: tab,
        })
      }
    }
  }

  render() {
    const { activeTab } = this.state
    const { record, translate } = this.props
    const surveyTypes = record ? record.surveys[activeTab] : null

    return (
      <div className="grid" style={{ height: '800px' }}>
        {surveyTypes &&
          surveyTypes.length > 0 &&
          surveyTypes.map((surveyType, index) => {
            return this.chunkArrayInGroups(surveyType.surveys, 8).map(
              (surveyChunk, surveyChunkIndex) => {
                return (
                  <div className="grid-surveys" key={index}>
                    <Surveys
                      key={index}
                      surveyType={surveyType.type_name}
                      surveys={surveyChunk}
                      record={record}
                      surveyPath={activeTab}
                      activeTab={activeTab}
                      translate={translate}
                    />
                  </div>
                )
              }
            )
          })}
      </div>
    )
  }
}

export default withLocalize(Child)
