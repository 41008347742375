import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { LocalizeProvider } from 'react-localize-redux'
import { ConnectedRouter } from 'react-router-redux'
import objectFitImages from 'object-fit-images'

import './helpers/analytics'
import { store, history } from './helpers'
import routes from './routes'
import App from './containers/App'
import './styles/index.scss'

const target = document.getElementById('root')

objectFitImages()

ReactDOM.render(
  <LocalizeProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <App>{routes}</App>
        </div>
      </ConnectedRouter>
    </Provider>
  </LocalizeProvider>,
  target
)
