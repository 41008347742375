import React from 'react'

import {Translate, withLocalize} from 'react-localize-redux'
import ReactTooltip from 'react-tooltip'
import {HelpCircle} from 'react-feather'

import {LoginModal} from '../../components/Modals'
import AccountRequestSentModal from '../../components/Modals/AccountRequestSentModal'
import {store} from '../../helpers'
import {signUpRequestServices} from '../../services'
import {alertActions} from '../../actions'
import {FormErrors} from '../../components/FormErrors'

let cancel = {exec: null}

class AccountRequest extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            first_name: '',
            last_name: '',
            phone_number: '',
            comments: '',
            formErrors: {
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                comments: ''
            },
            emailValid: false,
            firstNameValid: false,
            lastNameValid: false,
            phoneNumberValid: false,
            formValid: false,
            showSentModal: false,
            showLoginModal: false,
            cities: [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toggleSentModal = this.toggleSentModal.bind(this)
        this.toggleLoginModal = this.toggleLoginModal.bind(this)
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
    }

    componentWillUnmount() {
        if (cancel.exec) cancel.exec()
    }

    handleChange(event) {
        const {name, value} = event.target

        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        })
    }

    handleSubmit(event) {
        event.preventDefault()
        const {
            formValid,
            email,
            first_name,
            last_name,
            phone_number,
            comments

        } = this.state
        const {dispatch} = store

        if (formValid) {
            const params = {
                email,
                first_name,
                last_name,
                phone_number,
                comments
            }

            signUpRequestServices.create(params).then(
                response => {
                    this.setState({
                        email: '',
                        first_name: '',
                        last_name: '',
                        phone_number: '',
                        comments: '',
                        formErrors: {
                            email: '',
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                            comments: ''
                        },
                        emailValid: false,
                        firstNameValid: false,
                        lastNameValid: false,
                        phoneNumberValid: false,
                        formValid: false,
                        showSentModal: true,
                    })
                },
                error => {
                    if (error.response) {
                        const errors = error.response.data.errors
                        if (errors) {
                            errors.forEach(e => {
                                dispatch(alertActions.error(e))
                            })
                        }
                    }
                }
            )
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors
        let {
            emailValid,
            firstNameValid,
            lastNameValid,
            phoneNumberValid,
        } = this.state

        const {translate} = this.props

        switch (fieldName) {
            case 'email':
                if (value !== '') {
                    emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
                    fieldValidationErrors.email = emailValid
                        ? ''
                        : translate('signup.form.errors.email')
                } else fieldValidationErrors.email = ''
                break
            case 'phone_number':
                phoneNumberValid = value !== '' && value.length >= 10
                break
            case 'first_name':
                firstNameValid = value !== ''
                break
            case 'last_name':
                lastNameValid = value !== ''
                break
            default:
                break
        }

        this.setState(
            {
                formErrors: fieldValidationErrors,
                emailValid: emailValid,
                firstNameValid: firstNameValid,
                lastNameValid: lastNameValid,
                phoneNumberValid: phoneNumberValid
            },
            this.validateForm
        )
    }

    validateForm() {
        const {
            emailValid,
            firstNameValid,
            lastNameValid,
            phoneNumberValid,
        } = this.state

        this.setState({
            formValid:
                emailValid &&
                firstNameValid &&
                lastNameValid &&
                phoneNumberValid
        })
    }

    toggleLoginModal() {
        const {showLoginModal} = this.state

        this.setState({
            showLoginModal: !showLoginModal,
        })
    }

    handleLoginSubmit() {
        const {isAuthenticated} = this.props

        if (isAuthenticated) {
            this.setState({
                showLoginModal: false,
            })
        }
    }

    toggleSentModal() {
        const {showSentModal} = this.state

        this.setState({
            showSentModal: !showSentModal,
        })
    }

    render() {
        const {
            showLoginModal,
            showSentModal,
            formErrors,
            email,
            first_name,
            last_name
        } = this.state
        const {isAuthenticated, translate} = this.props

        return (
            <div>
                <LoginModal
                    showModal={showLoginModal}
                    isAuthenticated={isAuthenticated}
                    onClose={this.toggleLoginModal}
                    onSubmit={this.handleLoginSubmit}
                />
                <AccountRequestSentModal
                    showModal={showSentModal}
                    onClose={this.toggleSentModal}
                />
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-9">
                            <h2 className="heading">
                                Request
                                <span>&nbsp;</span>
                                <span className="text-primary">Form</span>
                                <ReactTooltip
                                    id="tooltip"
                                    place="right"
                                    effect="solid"
                                    type="light"
                                    multiline
                                    className="description-tooltip"
                                />
                                <HelpCircle
                                    size={22}
                                    className="ml-2 accent-color-1"
                                    style={{cursor: 'pointer'}}
                                    data-tip="Once submitted, the SHRed team will later contact you to complete the screening/consent process"
                                    data-for={'tooltip'}
                                />
                            </h2>
                            <div className="mt-2 mb-2">
                                <FormErrors formErrors={formErrors}/>
                            </div>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        First Name
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                            value={first_name}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Last Name
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            value={last_name}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Email
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        <Translate id="signup.form.label.phoneNumber"/>
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            name="phone_number"
                                            placeholder={'403 123 1234'}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Comments
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <textarea
                                            className="form-control"
                                            name="comments"
                                            placeholder="optional"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    name="submit"
                                    className="btn btn-primary mt-5 mb-2"
                                    disabled={!this.state.formValid}
                                >
                                    Submit
                                </button>
                                <div>
                  <span
                      className="btn-custom"
                      onClick={this.toggleLoginModal}
                      style={{fontSize: '14px'}}
                  >
                    {translate('signup.haveAccount')}
                  </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withLocalize(AccountRequest)
