import React from 'react'
import { Translate } from 'react-localize-redux'

import { store } from '../../../helpers/index'
import ConsentFormModal from '../../Modals/ConsentFormModal'
import { userServices } from '../../../services'
import { alertActions, userActions } from '../../../actions'

let cancel = { exec: null }

export class Personal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: '',
      last_name: '',
      phone_number: '',
      showConsentModal: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleConsentModal = this.toggleConsentModal.bind(this)
  }

  componentDidMount() {
    const { dispatch } = store

    userServices.get(cancel).then(
      response => {
        const data = response.data.data

        this.setState({
          first_name: data.first_name ? data.first_name : '',
          last_name: data.last_name ? data.last_name : '',
          phone_number: data.phone_number ? data.phone_number : '',
          signature: data.signature ? data.signature : '',
          created: data.created ? data.created : '',
        })
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  componentWillUnmount() {
    if (cancel.exec) cancel.exec()
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    const {
      first_name,
      last_name,
      phone_number,
    } = this.state
    const { dispatch } = store

    dispatch(
      userActions.update(
        {
          first_name,
          last_name,
          phone_number,
        },
        this.props.translate
      )
    )
  }

  toggleConsentModal() {
    const { showConsentModal } = this.state

    this.setState({
      showConsentModal: !showConsentModal,
    })
  }

  render() {
    const {
      first_name,
      last_name,
      phone_number,
      showConsentModal,
      signature,
      created,
    } = this.state

    return (
      <div className="mt-5 mb-5">
        <ConsentFormModal
          showModal={showConsentModal}
          signatureAllowed={false}
          signature={signature}
          created={created}
          onClose={this.toggleConsentModal}
        />
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <span className="btn-custom" onClick={this.toggleConsentModal}>
                  {this.props.translate(
                      'profile.settings.personal.reviewAndPrintConsent'
                    ) +
                    ' ' +
                    this.props.translate('profile.settings.personal.consentTag')}
                  {' '}
                <Translate id="profile.settings.personal.formTag" />
              </span>
            </label>
          </div>
        <h5>
          <Translate id="profile.settings.personal.header" />
        </h5>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <Translate id="profile.settings.personal.form.label.firstName" />
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="first_name"
                value={first_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <Translate id="profile.settings.personal.form.label.lastName" />
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="last_name"
                value={last_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              <Translate id="profile.settings.personal.form.label.phoneNumber" />
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="tel"
                className="form-control"
                name="phone_number"
                placeholder={'403 123 1234'}
                value={phone_number}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary mt-2">
              <Translate id="profile.settings.personal.form.label.submit" />
            </button>
          </div>
        </form>
      </div>
    )
  }
}
