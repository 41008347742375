import React from 'react'
import ReactTooltip from 'react-tooltip'
import {HelpCircle} from 'react-feather'

export const Section = props => {
    const {heading} = props
    const headArr = heading && heading.split(' ')
    let tip = ''

    if (heading === "Surveys") {
        tip = "Please complete the surveys below. RED means the " +
            "survey is not yet complete, ORANGE means you have partially completed, and GREEN means the survey has " +
            "been completed"
    }

    return (
        heading ? (
                <div className="mt-4 mb-3">
                    {headArr && (
                        <div>
                            {tip && (
                                <ReactTooltip
                                    id="tooltip"
                                    place="right"
                                    effect="solid"
                                    type="light"
                                    multiline
                                    className="description-tooltip"
                                />
                            )}
                            <h4
                                className="d-inline-block mb-4 ml-2"
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                {headArr.shift() + ' '}
                                <span className="text-primary">{headArr.join(' ')}</span>
                            </h4>
                            <HelpCircle
                                size={22}
                                className="ml-2 accent-color-1"
                                style={{cursor: 'pointer'}}
                                data-tip={tip}
                                data-for={'tooltip'}
                            />
                        </div>
                    )}
                </div>
            ) :
            <div className="mt-4 mb-3">
                <h4
                    className="d-inline-block mb-4 ml-2"
                    style={{display: 'flex', alignItems: 'center'}}
                >
                    <span className="text-primary">&#8203;</span>
                </h4>
            </div>
    )
}
